<template>
  <div v-if="!prevent" class="simple-menu m-t-xs flex justify-between column full-width e-input-modern size1">
      <div class="stask-search stask-search-suporte">
        <e-row mr>
          <e-col style="max-width: 200px">
            <erp-s-field label="Situação">
            <u-select
                placeholder="Aberta ou fechada"
                hide-underline inverted-light class="no-box-shadow"
                multiple v-model="filters.internalStatus" :options="[
          {label: 'Abertas', value: 'opened'},
          {label: 'Fechadas', value: 'closed'},
          ]"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Tipo">
              <type-select placeholder="Qualquer" v-model="filters.type"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field required label="Fila">
              <task-queue-select with-no-project v-model="filters.queue" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Prioridade">
              <priority-select v-model="filters.priority"/>
            </erp-s-field>
          </e-col>
          <e-col style="min-width: 150px">
            <date-input label="Data (De)" v-model="filters.data1"/>
          </e-col>
          <e-col style="min-width: 150px">
            <date-input label="Data (Até)" v-model="filters.data2"/>
          </e-col>
          <e-col style="min-width: 150px">
            <erp-s-field
                label="Filtro Data"
            >
              <u-select
                  hide-underline inverted-light class="no-box-shadow"
                  :options="[
                      {label: 'Criação', value: 'createdAt'},
                      {label: 'Início', value: 'startTime'},
                      {label: 'Prazo', value: 'deadline'},
                  ]"
                  v-model="filters.tipoData"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <!--<e-col>
            <erp-s-field label="Relator">
              <person-select v-model="filters.author"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Responsável">
              <person-select v-model="filters.assigned"/>
            </erp-s-field>
          </e-col>-->

        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field label="Relator">
              <person-select v-model="filters.author" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Responsável">
              <person-select v-model="filters.assigned" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Projeto">
              <project-select v-model="filters.project" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Cliente">
              <person-select v-model="filters.cliente" />
            </erp-s-field>
          </e-col>
        </e-row>
      </div>

      <e-row class="m-t" mr>
        <e-col class="text-right">
          <a @click="reset" class="m-r font-12 text-negative">Remover filtros</a>
          <e-btn label="Buscar" @click="buscarFechar"/>
        </e-col>
      </e-row>
    </div>
</template>

<script>
import {UPopover, USelect} from "uloc-vue"
import {ErpInput, ErpSField, ErpSelect} from "uloc-vue-plugin-erp"
import ECol from "@/components/layout/components/Col"
import ERow from "@/components/layout/components/Row"
import filters from "@/domain/tarefas/helpers/filtersConsole"
import DateInput from "@/reuse/input/Date.vue"
import TypeSelect from "components/suporte/components/task/include/TypeSelect.vue";
import PrioritySelect from "components/suporte/components/task/include/PrioritySelect.vue";
import PersonSelect from "components/suporte/components/projetos/include/PersonSelect.vue";
import ProjectSelect from "components/suporte/components/projetos/include/ProjectSelect.vue";
import TaskQueueSelect from "components/suporte/components/task/include/QueueSelect.vue";

export default {
  name: "SuporteClienteTaskFiltrosAdicionais",
  inject: ['container'],
  props: ['filters'],
  components: {
    TaskQueueSelect,
    ProjectSelect,
    // PersonSelect,
    PrioritySelect,
    TypeSelect,
    ErpSField,
    DateInput,
    ERow,
    ECol,
    USelect,
    //MenuOptionsItem,
    PersonSelect,
    //ErpSField,
  },
  mounted() {
  },
  watch: {},
  data() {
    return {
      prevent: false
    }
  },
  beforeDestroy() {
  },
  methods: {
    activated() {
      this.$nextTick(() => {
        // this.$refs.input0.focus()
      })
    },
    buscar() {
      this.container.pesquisar()
    },
    buscarFechar() {
      this.container.pesquisar()
    },
    reset() {
      this.prevent = true
      this.container.table.filters = JSON.parse(JSON.stringify(filters))
      this.$nextTick(() => {
        this.prevent = false
      })
      this.container.pesquisar()
    }
  }
}
</script>
